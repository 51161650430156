<template>
	<footer class="footer">
		<div class="container">
			<div>
				<p>© {{year}} — все права защищены</p>
			</div>
			<div>
				<a href="/privacy.pdf" target="_blank" class="politic">политика конфиденциальности</a>
			</div>
			<div>
				<p>адрес: Казань, ул. Зур Урам, 1а</p>
			</div>
			<div>
				<p>тел.: <a href="tel:88432392119">8 (843) 239-21-19</a></p>
			</div>
			<div>
				<p>e-mail: <a href="mailto:info@artrockets.ru">info@artrockets.ru</a></p>
			</div>
		</div>
	</footer>
</template>


<style lang="sass">
	@import '@/assets/sass/Footer'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	import vacanciesData from "@/assets/json/vacancies.json";

	export default {
		data: () => ({
			year: ""
		}),
		mounted(){
			this.year = new Date().getFullYear();
		}
	}
</script>
